import React, { useRef, useState, useEffect } from "react";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import EthCrypto from "eth-crypto";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export const Header = (props) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const tokenData = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("How rare will your Mutant Punk be?");
  const [claimingNft, setClaimingNft] = useState(false);
  const [whitelisting, setWhitelisting] = useState(false);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = (countdownDate) => {
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      clearInterval(interval.current);
    } else {
      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    }
  };

  function saveInLocalStorage(time) {
    localStorage.setItem("timer", time);
  }

  useEffect(() => {
    getData();

    const launchDate = new Date('2021-10-15T10:00:00');
    const countdownDate = launchDate.getTime();
    saveInLocalStorage(countdownDate);
    interval.current = setInterval(() => {
      startTimer(+countdownDate);
    }, 1000);

    return () => clearInterval(interval.current);
  }, [blockchain.account]);

  /*************/
  /* ClaimNFTs */
  /*************/

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }

    // if (whitelistMap.get(blockchain.web3.utils.toChecksumAddress(blockchain.account).toLowerCase()) != true) {
    //   setFeedback("Your wallet was not whitelisted for pre-sale reserved exclusively for Early VIPs only.");
    //   setClaimingNft(false);
    // } else {
    setFeedback("Preparing your Mutant Punks NFT...");
    setClaimingNft(true);
    // sign whitelist wallet
    const EthCrypto = require("eth-crypto");
    const message = EthCrypto.hash.keccak256([
      { type: "address", value: blockchain.account },
    ]);
    const privateKey = '0xa11772c3572c52e8fb1f4b193924dc0d0b382f03d8dc3126bcfff4af91262791';
    const signature = EthCrypto.sign(privateKey, message);
    blockchain.smartContract.methods
      .publicMint(blockchain.account, _amount)
      .send({
        // gasLimit: "500000", //remix suggested 275685gas
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: "0x30975aCac70B5d774D6f756ACd03a9B90CD4D4F5", //TODO: change to final Mutant Punks NFT contract
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.0 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("It seems the transaction was cancelled.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Woohoo! You just minted Mutant Punks NFT! All Mutant Punks will be revaled on Opensea.io soon."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
    // }
  };

  // const whitelistUser = () => {
  //   setFeedback("Preparing to whitelist your wallet...");
  //   setWhitelisting(true);

  //   if (whitelistMap.get(blockchain.web3.utils.toChecksumAddress(blockchain.account).toLowerCase()) != true) {
  //     setFeedback("Your wallet was not added in vip-wallet channel and not allowed to be whitelisted.");
  //     setWhitelisting(false);
  //   } else {
  //     blockchain.smartContract.methods
  //       .whitelistUser(blockchain.account)
  //       .send({
  //         gasLimit: "285000",
  //         to: "0xb27eb4f7Ae223633707dcf1CdCdE8756b28E5432", //TODO: change to final Mutant Punks NFT contract
  //         from: blockchain.account,
  //       })
  //       .once("error", (err) => {
  //         console.log(err);
  //         setFeedback("It seems the transaction was cancelled.");
  //         setWhitelisting(false);
  //       })
  //       .then((receipt) => {
  //         setFeedback(
  //           "Woohoo! You have been whitelisted! Come back on Oct 15th to free mint your Mutant Punks."
  //         );
  //         setWhitelisting(false);
  //         dispatch(fetchData(blockchain.account));
  //       });
  //   }
  // }

  const [mintAmountState, setMintAmountState] = useState(2);

  const handleIncrement = () => {
    if (mintAmountState + 1 <= 4) {
      setMintAmountState(mintAmountState + 1);
    }
  };

  const handleDecrement = () => {
    if (mintAmountState - 1 >= 1) {
      setMintAmountState(mintAmountState - 1);
    }
  };

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph1 : 'Loading'}</p>
                {/* <p>{props.data ? props.data.paragraph2 : 'Loading'}</p> */}
                {/* <p>{props.data ? props.data.paragraph3 : 'Loading'}</p> */}
                <a href="https://opensea.io/collection/mutant-punks-nft" target="blank"><h2>SOLDOUT!</h2></a>
                {/* <h2>{timerDays} days {timerHours} hours {timerMinutes} minutes {timerSeconds} seconds</h2> */}
                <s.SpacerSmall />
                <a
                  className='btn btn-custom btn-lg page-scroll'
                  href = "https://opensea.io/collection/mutant-punks-nft"
                  target="blank"
                >
                  Buy on Opensea.io
                </a>

                {/* Mint Button */}

                {/* {Number(tokenData.totalSupply) > 0 ? (
                  <>
                    <h1>{tokenData.totalSupply}/10000</h1>
                  </>
                ) : (<> </>)}

                {Number(tokenData.totalSupply) === 10000 ? (
                  <>
                    <p>Sale has ended, you can still buy on opensea</p> //TODO add opensea link
                  </>
                ) : (
                  <>
                    {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT WALLET
                        </a>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription style={{ textAlign: "center" }}>
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />

                        <ButtonGroup size="large" aria-label="large outlined button group">
                          <Button style={{ fontSize: '25px', backgroundColor: '#5ca9fb', color: '#fff' }} onClick={handleDecrement}>-</Button>
                          <Button style={{ fontSize: '25px', backgroundColor: '#5ca9fb', color: '#fff' }} disabled>{mintAmountState}</Button>
                          <Button style={{ fontSize: '25px', backgroundColor: '#5ca9fb', color: '#fff'}} onClick={handleIncrement}>+</Button>
                        </ButtonGroup>
                        <s.SpacerSmall />
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs(mintAmountState);
                            getData();
                          }}
                        >
                          {claimingNft ? "Minting..." : "Mint " + mintAmountState + " Free Mutant Punk"}
                        </a>
                        <p>{feedback}</p>
                      </s.Container>

                    )}
                  </>
                )} */}

                {/* Mint button ends */}


                {/* Whitelist Button */}
                {/* <>
                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.SpacerSmall />
                      <a
                        className='btn btn-custom btn-lg page-scroll'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT WALLET
                      </a>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription style={{ textAlign: "center" }}>
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.SpacerSmall />
                      <a
                        className='btn btn-custom btn-lg page-scroll'
                        disabled={whitelisting ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          whitelistUser();
                          getData();
                        }}
                      >
                        {whitelisting ? "Whitelisting..." : "Whitelist"}
                      </a>
                      <p>{feedback}</p>
                    </s.Container>

                  )}
                </> */}
                {/* Whitelist Button ends */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
