import React, { useRef, useState, useEffect } from "react";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import EthCrypto from "eth-crypto";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export const RMutantCoin = (props) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const tokenData = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState();
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState('init');
  const [claimedAmount, setClaimedAmount] = useState(0);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  let interval = useRef();

  useEffect(() => {
    getData();
    return () => clearInterval(interval.current);
  }, [blockchain.account]);

  /*****************/
  /* Claim Rewards */
  /*****************/

  const claimRewards = (tokenData) => {
    setFeedback("Claiming rMutantCoins...");
    setClaimingNft(true);
    setClaimedAmount(Math.round(tokenData.pendingReward/Math.pow(10, 18) * 10000)/10000);
    blockchain.smartContract.methods
      .yieldReward()
      .send({
        // gasLimit: "500000", //remix suggested 275685gas
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: "0x39A15a0695c77cbe5fd4F06AB0cCB7bAd62F696F", //TODO: change to mainnet rMutantCoin
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.0).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("It seems the transaction was cancelled.");
        setClaimingNft(false);
        setClaimSuccess('false');
      })
      .then((receipt) => {
        setFeedback(
          "Successfully claimed rMutantCoins. Tweet about your rMutantCoins!"
        );
        setClaimingNft(false);
        setClaimSuccess('true');
        dispatch(fetchData(blockchain.account));
      });
    // }
  };

  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Mutant Coin</h2>
          <p>
            Each Mutant Punk NFT is a membership key to the exclusive Mutant Punks Metaverse in which you will gain early access to future break-through NFT & Defi projects, giveaways and exclusive membership benefits.
          </p>
          <p>
            Connect your wallet holding MutantPunksNFTs to claim rMutantCoin tokens, soon to be swapped for MutantCoin 1:1 when <a href="https://docs.mutantswap.finance/" target="_blank">MutantSwap</a> Defi AMM DEX launches.
          </p>
          {/* Mint Button */}
                {Number(tokenData.pendingReward) > 0 ? (
                  <>
                    <h1>{Math.round(tokenData.pendingReward/Math.pow(10, 18) * 10000)/10000} unclaimed rMutantCoins</h1>
                  </>
                ) : (<> </>)}
                  <>
                    {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />
                        <s.SpacerSmall />
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT WALLET
                        </a>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription style={{ textAlign: "center" }}>
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        {claimSuccess != 'true' && Number(tokenData.pendingReward) == 0 && blockchain.account != "" && blockchain.smartContract != null ? (
                          <>
                            <h1>You have no rMutantCoin to claim</h1>
                          </>
                        ) : (<>
                        <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />
                        <s.SpacerSmall />
                        {claimSuccess != 'true' ? (
                          <>
                        <a
                          className='btn btn-custom btn-lg page-scroll'
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimRewards(tokenData);
                            getData();
                          }}
                        >
                          {claimingNft ? "Claiming..." : "Claim rMutantCoins"}
                        </a>
                        <p>{feedback}</p>
                        </>
                        )
                        : (
                          <>
                          <a className='btn btn-custom btn-lg page-scroll twitter-share-button'
                            href={`https://twitter.com/intent/tweet?text=I've just claimed ` + claimedAmount + ` rMutantCoin $rMC tokens, a governance token of MutantSwap DEX, just by holding @MutantPunksNFT in my wallet%0a%0a&hashtags=NFTs,NFTCollectors,NFTCommunity,NFTArt,NFTCollections,DeFi,DEX&url=https://opensea.io/collection/mutant-punks-nft`}
                            target="_blank"
                            data-size="large"
                            data-related="twitterapi,twitter"
                          >
                          Tweet
                          </a>
                          <p>{feedback}</p>
                          </>
                          )
                        }
                      </s.Container>
                      </>)}
                      </>
                    )}
                  </>
                {/* Mint button ends */}
        </div>
      </div>
    </div>
  )
}
