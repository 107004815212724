export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.discord : '/'}>
                    <i class="fa fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            Mutant Punks NFT is not in any association with Crypto Punks or Larvalabs
          </p>
          <p>
            &copy; 2021 Mutant Punks NFT
          </p>
        </div>
      </div>
    </div>
  )
}
